import { defineComponent } from 'vue'
import { appModule } from '../utils/store-accessor'

declare global {
    interface Window {
        isQueueWorker: boolean | undefined
    }
}

export default defineComponent({
    name: 'WithGrooveHq',
    computed: {
        grooveBrand(): string {
            return appModule.brandConfig.name
        },
    },
    async mounted() {
        if (window.isQueueWorker) {
            return
        }

        await this.loadWidget()
    },
    methods: {
        async loadWidget() {
            const grooveScript = await import('../libraries/grooveWidget.js')
            grooveScript.init(this.getWidgetId())
        },
        getWidgetId() {
            if (this.grooveBrand === 'bingo') {
                return 'f64c00da-4d9b-446f-89d9-e03a382d0fcd'
            }

            if (this.grooveBrand === 'flashcards') {
                return '23d04af2-068a-4521-9a8e-a8dd41ee484f'
            }

            return '5e79401a-d781-4859-836d-7c17587b6a44'
        },
    },
})
